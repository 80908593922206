import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Tab,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { apiCall } from "../../apis/api";
import NotificationCard from "../NotificationCard";
import Pagination from "@mui/material/Pagination";
import { APP_FONT } from "../../constants/AppFont";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import {
  fetchMerchantNotificationsCount,
} from "../../redux/actions/merchant";
import { useDispatch, useSelector } from "react-redux";

export default function Ewallet() {
  const [value, setValue] = useState<string>("1");
  const [perPage, _] = useState<string>("5");

  const [notificationsList, setNotificationsList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [pagination, setPagination] = useState<any>({});

  const unreadNotifications = useSelector(
    (state: any) => state.merchant.unreadNotifications
  );

  const dispatch = useDispatch();

  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    fetchData(page);
  };

  useEffect(() => {
    fetchData(1);
  }, []);

  const fetchData = async (page: number) => {
    try {
      setLoading(true);
      const response = await apiCall(
        `api/v1/merchant/my-notifications?perPage=${perPage}&page=${page}`,
        "get"
      );
      setNotificationsList(response?.notifications?.data || []);
      setPagination(response?.notifications?.pagination || {});
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const markAsRead = async (id: number) => {
    try {
      // Check if the notification is already read
      const notification = notificationsList.find((notification: any) => notification.id === id);
      if (notification && notification.is_read === 1) {
        return; // Do nothing if already read
      }
      // If not read, make the API call to mark as read
      await apiCall(`/api/v1/merchant/read-notifications?id=${id}`, "patch");
      // Refresh notifications list after marking as read
      await fetchData(1);
      dispatch<any>(merchantNotifications());
    } catch (error) {
    }
  };

  const markAllAsRead = async () => {
    try {
      await apiCall(`/api/v1/merchant/read-notifications`, "patch");
      // Refresh notifications list after marking all as read
      await fetchData(1);
      dispatch<any>(merchantNotifications());
    } catch (error) {
    }
  };

  const merchantNotifications = async () => {
    try {
      await dispatch<any>(fetchMerchantNotificationsCount());
    } catch (error) {
    }
  };

  return (
    <Box sx={{ width: "97.5%", typography: "body1" }}>
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TabList
            textColor="secondary"
            indicatorColor="secondary"
            onChange={handleChange}
            aria-label="tabs example"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: 600,
              textTransform: "none",
            }}
          >
            <Tab
              sx={{ fontWeight: 600, fontFamily: APP_FONT }}
              label="Notifications"
              value="1"
            />
          </TabList>
          <IconButton
            onClick={markAllAsRead}
            color="secondary"
            disabled={unreadNotifications === 0}
          >
            <DoneAllIcon />
          </IconButton>
        </Box>
        <TabPanel sx={{ p: 0, pt: 2, pl: 0 }} value="1">
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              {notificationsList?.length > 0 ? (
                <>
                  <Grid container spacing={2}>
                    {notificationsList.map((notification: any) => (
                      <Grid item xs={12} key={notification.id}>
                        <NotificationCard
                          notification={notification}
                          onClick={() => markAsRead(notification.id)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  {pagination.total_pages > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 2,
                        marginBottom:2
                      }}
                    >
                      <Pagination
                        count={pagination.total_pages}
                        page={pagination.current_page}
                        onChange={handlePageChange}
                      />
                    </Box>
                  )}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "300px",
                  }}
                >
                  <Typography variant="h5" color="textSecondary">
                    No results found
                  </Typography>
                </Box>
              )}
            </>
          )}
        </TabPanel>
      </TabContext>
    </Box>
  );
}
