import {
  Alert,
  Box,
  Button,
  Stack,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { APP_FONT } from "../constants/AppFont";
import QRCode from "react-qr-code";

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
});

type Props = {
  qrCode: string;
  setError: any;
  setErrorMessage: any;
  merchantId: string;
};

const Qrcode = ({ qrCode, setError, setErrorMessage, merchantId }: Props) => {
  const printQRCode = () => {
    const printWindow = window.open("Qr code", "_blank");
    if (printWindow) {
      const qrCodeElement = document.createElement("div");
      qrCodeElement.innerHTML = `
        <html>
          <head>
            <title> </title>
          </head>
          <body style='text-align: center;'>
          <div style="text-align:center; margin-bottom:20px">
              Business QR Code
            </div>
            <div style="width: 450px; margin: auto;">
              ${document.getElementById("qr-code-to-print")?.innerHTML}
            </div>
          </body>
        </html>
      `;
      printWindow.document.write(qrCodeElement.innerHTML);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
    } else {
      setError(true);
      setErrorMessage("Error: Failed to open print window");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack direction={"column"} alignItems={"center"} mt={2} p={2}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            color: "#0E162C",
          }}
        >
          Your business QR code
        </Typography>
        {qrCode ? (
          <Box id="qr-code-to-print"  mt={2}>
            <QRCode
              size={212}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={`${process.env.REACT_APP_QR_CODE_URL}/${merchantId}`}
              viewBox={`0 0 252 252`}
            />
          </Box>
        ) : (
          <Alert severity="error">No QR Found</Alert>
        )}

        <Button
          onClick={printQRCode}
          style={{
            fontSize: "1rem",
            color: "#000",
            borderColor: "#ececec",
            textTransform: "none",
            marginBottom: "20px",
          }}
          variant="outlined"
        >
          <PrintIcon />
          Print
        </Button>
      </Stack>
    </ThemeProvider>
  );
};

export default Qrcode;
