import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Alert, AlertColor, Button, useMediaQuery } from "@mui/material";
import MerchantDashboard from "../MerchantDashboard";
import { useState } from "react";
import { APP_FONT } from "../../constants/AppFont";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ backgroundColor: "#ececec" }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor | null>(null);
  const [alertMessage, setAlertMessage] = useState("");
  const isMobileOrTablet = useMediaQuery("(max-width: 639px)");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", paddingLeft: isMobileOrTablet ? 0 : 30 }}>
      <Box sx={{ borderColor: "divider", paddingLeft: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="basic-tabs"
        >
          <Tab
            sx={{
              fontFamily: APP_FONT,
              fontWeight: 600,
              textTransform: "none",
              fontSize: isMobileOrTablet ? "12px" : "16px",
            }}
            label="At-A-Glance Report"
            {...a11yProps(0)}
          />
        
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {!!error && <Alert severity="error">{errorMessage}</Alert>}

        {/* Add more content here */}
        <MerchantDashboard
          setError={setError}
          setErrorMessage={setErrorMessage}
          alertSeverity={alertSeverity}
          setAlertSeverity={setAlertSeverity}
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Typography sx={{ fontWeight: 700, fontFamily: APP_FONT }} variant="h6">
          Coming Soon!
        </Typography>
      </CustomTabPanel>
    </Box>
  );
}
