import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import { APP_FONT } from "../../constants/AppFont";

interface MyModalProps {
  open: boolean;
  handleClose: () => void;
}

const style: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "8px",
  padding: "20px",
  textAlign: "center",
};

const closeButtonStyle: React.CSSProperties = {
  position: "absolute",
  top: "0px",
  right: "0px",
  padding:"7px",
  cursor:"pointer"
};

const MyModal: React.FC<MyModalProps> = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="my-modal-title"
      aria-describedby="my-modal-description"
    >
      <Box sx={{ ...style, backgroundColor: "white" }}>
        <Box onClick={handleClose} style={closeButtonStyle}>
          <CloseIcon />
        </Box>

        <Paper
          elevation={0}
          variant="outlined"
          sx={{
            backgroundColor: "#F6F4F4",
            padding: "20px",
            mt: 3,
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="h5"
                fontFamily={APP_FONT}
                fontWeight={600}
                style={{
                  marginTop: "0px",
                  marginBottom: "20px",
                  fontSize: "1rem",
                }}
              >
                Contact Merchant Support for Additional Information
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1}>
              <IconButton
                sx={{
                  backgroundColor: "#fff",
                  "&:hover": {
                    backgroundColor: "#fff",
                  },
                }}
              >
                <CallIcon sx={{ color: "#582F8E" }} />
              </IconButton>
            </Grid>
            <Grid item xs={11}>
              <Typography
                fontFamily={APP_FONT}
                fontWeight={600}
                style={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  fontSize: "0.9rem",
                }}
              >
                514-815-5668
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={1}>
              <IconButton
                sx={{
                  backgroundColor: "#fff",
                  "&:hover": {
                    backgroundColor: "#fff",
                  },
                }}
              >
                <MailIcon sx={{ color: "#582F8E" }} />
              </IconButton>
            </Grid>
            <Grid item xs={11}>
              <Typography
                fontFamily={APP_FONT}
                fontWeight={600}
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontSize: "0.9rem",
                }}
              >
                merchants@co-karla.com
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
};

export default MyModal;
