import { Typography, useMediaQuery } from "@mui/material";
import HomeDrawer from "../components/drawer/HomeDrawer";
import { APP_FONT } from "../constants/AppFont";

const ConsultLoyaltyAdvisor = () => {
  const isMobileOrTablet = useMediaQuery("(max-width: 639px)");

  return (
    <div>
      <HomeDrawer />
      <Typography
        fontFamily={APP_FONT}
        variant="h4"
        fontWeight={700}
        paddingTop={13}
        paddingLeft={isMobileOrTablet ? 2 : 33}
      >
        Consult Loyalty Advisor (Coming Soon)
      </Typography>
    </div>
  );
};

export default ConsultLoyaltyAdvisor;
