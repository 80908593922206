import React from "react";
import { Grid, Card, CardContent, Avatar, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { green, grey } from "@mui/material/colors";

interface Notification {
  id: number;
  title: string;
  message: string;
  is_read: number;
  created_at: string;
}

interface NotificationCardProps {
  notification: Notification;
  onClick?: () => void;
}

function NotificationCard({ notification, onClick }: NotificationCardProps) {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        minWidth: "100%",
        cursor: onClick ? "pointer" : "default", // Set cursor pointer if onClick is provided
      }}
      onClick={handleClick}
    >
      <CardContent sx={{ flex: "1" }}>
        <Typography variant="h6" component="div">
          {notification.title}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {notification.message}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {notification.created_at}
        </Typography>
      </CardContent>
      <Avatar sx={{ ml: "auto", mr: 1, mt: 1 }}>
        {notification.is_read ? (
          <CheckCircleIcon sx={{ color: green[500] }} />
        ) : (
          <CheckCircleIcon sx={{ color: grey[500] }} />
        )}
      </Avatar>
    </Card>
  );
}

export default NotificationCard;
