import React, { useEffect, useState } from "react";
import {
  TextField,
  Paper,
  Typography,
  Alert,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { merchantLogin } from "../../redux/actions/merchant";
import { MerchantLogin } from "../../models/merchant";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from '@mui/lab';

const Login = () => {
  const jwtToken = localStorage.getItem("jwtToken");
  const isAuthenticated = !!jwtToken; // Check if a JWT token exists
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
  })
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    setLoading(true);
    const body: MerchantLogin = {
      email: email,
      password: password
    }

    dispatch<any>(merchantLogin(body))
      .then(unwrapResult)
      .then(async (res: any) => {
        if (res.success) {
          // navigate to home page
          setLoading(false);
          const updatedPassword = localStorage.getItem('updatedPassword');
          if (updatedPassword === '0') {
            navigate('/change-password');
          } else {
            navigate('/');
          }
        } else {
          setLoading(false);
          setError(true);
          setErrorMessage('Authentication Error. No such user.');
        }
      })
      .catch((err: any) => {
        setLoading(false);
        setError(true);
        setErrorMessage('Authentication Error. No such user.');
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <div>
        <Typography variant="h4" gutterBottom align="center">
          Log into Karla's <br />
          Merchant Portal
        </Typography>
        <Paper
          elevation={3}
          style={{ padding: "20px", maxWidth: "400px", width: "100%" }}
        >
          {
            !!error && <Alert severity="error">{errorMessage}</Alert>
          }
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={email}
            onChange={handleEmailChange}
            margin="normal"
            required={true}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            margin="normal"
            required={true}
          />
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            onClick={handleLogin}
            fullWidth
            disabled={!email || !password}
          >
            Login
          </LoadingButton>
        </Paper>
      </div>
    </div>
  );
};

export default Login;
