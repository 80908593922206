import {combineReducers, configureStore} from '@reduxjs/toolkit';
import merchantReducer from './reducers/merchantSlice';

const rootReducer = combineReducers({
  merchant: merchantReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const Store = configureStore({
  reducer: rootReducer,
});

export default Store;
