import React, { useEffect, useState } from "react";
import DataTable from "../table/Table";
import { Box, Tab } from "@mui/material";
import { APP_FONT } from "../../constants/AppFont";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { fetchEwalletData } from "../../redux/actions/merchant";
import BusinessEwallet from "../BusinessEwallet";

type EwalletDataType = [];

export default function Ewallet() {
  const [value, setValue] = useState<string>("1");
  const [eWalletData, setEWalletData] = useState<EwalletDataType>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const dispatch = useDispatch();

  const columns = [
    { label: "ID", key: "id" },
    { label: "$ Amount", key: "amount" },
    { label: "Invoice Url", key: "invoice_url" },
    { label: "Status", key: "status" },
    { label: "Date", key: "date" },
  ];

  const fetchData = async (page: any, rowsPerPage: any) => {
    try {
      if (value === "1") {
        setLoading(true);
        dispatch<any>(fetchEwalletData({ page, rowsPerPage }))
          .then(unwrapResult)
          .then((res: any) => {
            if (res) {
              setEWalletData(res?.data);
              setPagination(res?.pagination);
              setLoading(false);
            }
          });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, [value, page, rowsPerPage]);

  useEffect(() => {
    setPage(1); // Reset page to 1 when tab changes
    setRowsPerPage(5); // Reset rowsPerPage to 1 when tab changes
    fetchData(1, 5); // Fetch data with initial page and rowsPerPage
  }, [value]);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "97.5%", typography: "body1", fontFamily: APP_FONT }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            textColor="secondary"
            indicatorColor="secondary"
            onChange={handleChange}
            aria-label="tabs example"
            sx={{
              fontFamily: APP_FONT,
              fontWeight: 600,
              textTransform: "none",
            }}
          >
            <Tab
              sx={{ fontWeight: 600, fontFamily: APP_FONT, }}
              label="E-Wallet"
              value="1"
            />
          </TabList>
        </Box>
        <TabPanel sx={{p:0,pt:2,pl:0}} value="1">
          <Box>
            <BusinessEwallet />
          </Box>
          <DataTable
            rows={eWalletData}
            loading={loading}
            pagination={pagination}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            columns={columns}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
