import axios from "axios";

export const apiCall = async (
  url: string,
  method: any,
  data?: any,
  params?: any
) => {
  const jwtToken = await localStorage.getItem("jwtToken");

  const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
  });
  try {
    const res = await api({ url, method, data, params });
    return res.data;
  } catch (error) {
    throw new Error('An error occurred during the API call.');
  }
};