import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";

const QrCodeRedirect = () => {
  const { merchantId } = useParams();
  const [isPlatformIosOrAndroid, setIsPlatformIosOrAndroid] = useState<
    string | undefined
  >();

  useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const isAndroid = /Android/.test(navigator.userAgent);

    if (isIOS) {
      setIsPlatformIosOrAndroid("ios");
    } else if (isAndroid) {
      setIsPlatformIosOrAndroid("android");
    } else {
      setIsPlatformIosOrAndroid(undefined);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // if (isPlatformIosOrAndroid === "ios") {
    // window.location.href = `https://apps.apple.com/pk/app/karla-rewards-rent-discounted/id6449532168?merchantId=${merchantId}`;
    // window.location.href = `https://testflight.apple.com/join/N26fDmZ7?merchantId=${merchantId}`;
    // } else if (isPlatformIosOrAndroid === "android") {
    // window.location.href = `https://play.google.com/store/apps/details?id=com.karla.co.app.karla&pli=1&merchantId=${merchantId}`;
    // }
    // No redirection needed for other cases
    // eslint-disable-next-line
    if (
      isPlatformIosOrAndroid === "ios" ||
      isPlatformIosOrAndroid === "android"
    ) {
      window.location.href = `https://karla.page.link/?link=https%3A%2F%2Fkarla.page.link%2FqrCode%3FmerchantId%3D${merchantId}&apn=com.karla.co.app.karla&isi=6449532168&ibi=com.karla.co.app.karla`;
    }
  }, [merchantId, isPlatformIosOrAndroid]);

  return (
    <>
      {!isPlatformIosOrAndroid ? (
        <Typography>Oops, your operating system is not supported!</Typography>
      ) : (
        <Typography>
          You will be redirected to your respective store...
        </Typography>
      )}
    </>
  );
};

export default QrCodeRedirect;
