import { ThemeProvider, createTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Box, Divider, LinearProgress, Paper, Stack } from "@mui/material";
import { ReactComponent as GrossIcon } from "../../assets/images/logos/money-02.svg";
import { ReactComponent as AverageIcon } from "../../assets/images/logos/Group 14109.svg";
import { ReactComponent as TransactionsCount } from "../../assets/images/logos/Group 14110.svg";
import { ReactComponent as KarlaPurple } from "../../assets/images/logos/Vector (1).svg";
import { APP_FONT } from "../../constants/AppFont";
import { useEffect, useState } from "react";
import { getMerchantStats } from "../../redux/actions/merchant";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
});

const Annually = () => {
  const [grossSale, setGrossSale] = useState(0);
  const [averageSale, setAverageSale] = useState(0);
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [feesDollarAmount, setFeesDollarAmount] = useState(0);
  const [newCustomerCount, setNewCustomerCount] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();


  useEffect(() => {
    const filter = "ANNUALLY";
    dispatch<any>(getMerchantStats(filter))
    .then(unwrapResult)
    .then((res: any) => {
      if (res) {
        setLoading(false);
        setGrossSale(res?.data?.gross_sale);
        setAverageSale(res?.data?.average_sale);
        setTransactionsCount(res?.data?.total_transactions);
        setPercentage(res?.data?.percentage);
        setFeesDollarAmount(res?.data?.fees_dollar_amount);
        setNewCustomerCount(res?.data?.new_customers);
      } else {
        setError(true);
        setErrorMessage("Error. Could not fetch merchant stats.");
      }
    })
    .catch((err: any) => {
      console.log(err);
      setError(true);
      setErrorMessage("Error. Could not fetch merchant stats.");
    })
    // eslint-disable-next-line
  }, []);

  if (error) {
    return <Typography>Unexpected error</Typography>
  }

  if (errorMessage) {
    return <Typography>{errorMessage}</Typography>
  }

  if (loading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Stack spacing={2}>
        <Paper
        elevation={2}
        style={{
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <GrossIcon style={{ marginRight: 10 }} />
          <Typography
            sx={{
              fontSize: "13px",
              color: "#0E162C",
            }}
          >
            Gross Sale
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              color: "#0E162C",
              marginLeft: "auto",
            }}
          >
            ${grossSale.toFixed(2)}
          </Typography>
        </Stack>
      </Paper>

      <Paper
        elevation={2}
        style={{
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <AverageIcon style={{ marginRight: 10 }} />
          <Typography
            sx={{
              fontSize: "13px",
              color: "#0E162C",
            }}
          >
            Average Sale
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              color: "#0E162C",
              marginLeft: "auto",
            }}
          >
            ${averageSale.toFixed(2)}
          </Typography>
        </Stack>
      </Paper>

      <Paper
        elevation={2}
        style={{
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <TransactionsCount style={{ marginRight: 10 }} />
          <Typography
            sx={{
              fontSize: "13px",
              color: "#0E162C",
            }}
          >
            Number of Transactions
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              color: "#0E162C",
              marginLeft: "auto",
            }}
          >
            {transactionsCount}
          </Typography>
        </Stack>
      </Paper>
        <Paper
          elevation={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <KarlaPurple style={{ marginRight: 10 }} />
            <Stack direction={"column"}>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#0E162C",
                }}
              >
                Fees
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "#949494",
                }}
              >
                Percentage
              </Typography>
            </Stack>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#0E162C",
                marginLeft: "auto",
              }}
            >
              {percentage}%
            </Typography>
          </Stack>
        </Paper>
        <Paper
          elevation={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <KarlaPurple style={{ marginRight: 10 }} />
            <Stack direction={"column"}>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "#0E162C",
                }}
              >
                Fees
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "#949494",
                }}
              >
                Dollar amount
              </Typography>
            </Stack>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#0E162C",
                marginLeft: "auto",
              }}
            >
               ${feesDollarAmount.toFixed(2)}
            </Typography>
          </Stack>
        </Paper>
        <Divider />
      </Stack>
      <Paper
        elevation={2}
        style={{
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          marginTop: 20,
        }}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <KarlaPurple style={{ marginRight: 10 }} />
          <Typography
            sx={{
              fontSize: "13px",
              color: "#0E162C",
            }}
          >
            Number of New Customers
          </Typography>
          {/* <GreenIcon style={{ marginLeft: "12%", marginRight: "2px" }} /> */}
          <Typography
            sx={{
              fontSize: "12px",
              color: "#00A51A",
              fontWeight: 500,
            }}
          >
            {/* 10% */}
          </Typography>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: "20px",
              color: "#0E162C",
              marginLeft: "auto",
            }}
          >
            {newCustomerCount}
          </Typography>
        </Stack>
      </Paper>
    </ThemeProvider>
  );
};

export default Annually;
