import * as React from "react";
import { createTheme } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import { APP_FONT } from "../../constants/AppFont";
import Day from "./Day";
import Week from "./Week";
import Quarterly from "./Quarterly";
import Annually from "./Annually";

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        textColor="inherit"
        sx={{ "& .MuiTabs-indicator": { display: "none" } }}
      >
        <Tab
          label="Day"
          {...a11yProps(0)}
          sx={{ textTransform: "none" }}
          disableRipple
        />
        <Tab
          label="Week"
          {...a11yProps(1)}
          sx={{ textTransform: "none" }}
          disableRipple
        />
        <Tab
          label="Quarterly"
          {...a11yProps(2)}
          sx={{ textTransform: "none" }}
          disableRipple
        />
        <Tab
          label="Annually"
          {...a11yProps(3)}
          sx={{ textTransform: "none" }}
          disableRipple
        />
      </Tabs>

      <Divider sx={{ mt: 1 }} />
      <TabPanel value={value} index={0} dir={theme.direction}>
        <Day />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <Week />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <Quarterly />
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        <Annually />
      </TabPanel>
    </div>
  );
}
