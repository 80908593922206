export const ActionTypes = {
  MERCHANT_LOGIN: 'MERCHANT_LOGIN',
  MERCHANT_LOGOUT: 'MERCHANT_LOGOUT',
  MERCHANT_CHANGE_PASSWORD: 'MERCHANT_CHANGE_PASSWORD',
  MERCHANT_HOME: 'MERCHANT_HOME',
  MERCHANT_STATS: 'MERCHANT_STATS',
  STORE_PAYMENT_SUCCESS_DATA:'STORE_PAYMENT_SUCCESS_DATA',
  FETCH_PLAID_AND_RECEIPT:"FETCH_PLAID_AND_RECEIPT",
  FECTH_E_WALLET_DATA:"FECTH_E_WALLET_DATA",
  FETCH_MERCHANT_NOTIFICATIONS:"FETCH_MERCHANT_NOTIFICATIONS"

};
