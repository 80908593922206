import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";

interface SkeletonLoaderProps {
  columns: number;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ columns }) => (
  <>
    {[...Array(2)].map((_, rowIndex) => (
      <TableRow key={rowIndex}>
        {[...Array(columns)].map((_, colIndex) => (
          <TableCell key={colIndex}>
            <Skeleton
              variant="rectangular"
              animation="pulse"
              width="100%"
              height={15}
              sx={{
                borderRadius: 1,
              }}
            />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </>
);

export default SkeletonLoader;
