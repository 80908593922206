import { Typography, useMediaQuery } from "@mui/material";
import HomeDrawer from "../components/drawer/HomeDrawer";
import { APP_FONT } from "../constants/AppFont";
import EwalletTab from "../components/tabs/Ewallet";


const EWallet = () => {
  const isMobileOrTablet = useMediaQuery('(max-width: 639px)');

  return (
    <div>
      <HomeDrawer />
        <Typography
          fontFamily={APP_FONT}
          variant="h4"
          fontWeight={700}
          paddingTop={13}
          paddingLeft={isMobileOrTablet ? 2 : 33}
        >
       <EwalletTab />
        </Typography>
    </div>
  );
};

export default EWallet;