import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HomeDrawer from "../components/drawer/HomeDrawer";
import { Box, Typography, useMediaQuery } from "@mui/material";
import BasicTabs from "../components/tabs/Tabs";
import { APP_FONT } from "../constants/AppFont";
import CsvDownloader from "../components/CsvDownloader";



const Home = () => {
  const navigate = useNavigate();

  const isMobileOrTablet = useMediaQuery("(max-width: 639px)");
  
  const jwtToken = localStorage.getItem("jwtToken");
  const isAuthenticated = !!jwtToken;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  return (
    <div>
      <HomeDrawer />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: "",
          width: "98.1%",
        }}
      >
        <Typography
          variant="h4"
          fontWeight={700}
          paddingTop={13}
          paddingLeft={isMobileOrTablet ? 3 : 33.5}
          paddingBottom={1}
          fontFamily={APP_FONT}
        >
          Dashboard
        </Typography>
        <Typography
          variant="h4"
          fontWeight={700}
          paddingTop={13}
          paddingLeft={isMobileOrTablet ? 3 : 3.5}
          paddingBottom={1}
          marginRight={isMobileOrTablet ? 0.5 : 0}
        >
          <CsvDownloader />
        </Typography>
      </Box>

      <BasicTabs />
    </div>
  );
};

export default Home;
