import "./App.css";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Login from "./pages/authentication/Login";
import Home from "./pages/Home";
import ChangePassword from "./pages/authentication/ChangePassword";
import Transactions from "./pages/Transactions";
import EWallet from "./pages/EWallet";
import Notifications from "./pages/Notifications";
import ConsultLoyaltyAdvisor from "./pages/ConsultLoyaltyAdvisor";
import QrCodeRedirect from "./pages/QrCodeRedirect";

function App() {

  return (
    <div>
      <Router>
        <Routes>
          <Route
            path="/consult-loyalty-advisor"
            element={<ConsultLoyaltyAdvisor />}
          />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/e-wallet" element={<EWallet />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/qr-code/:merchantId" element={<QrCodeRedirect />} />

          <Route path="/change-password" element={<ChangePassword />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
