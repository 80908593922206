import {createSlice} from '@reduxjs/toolkit';
import { merchantChangePassword, merchantHome, merchantLogin, getMerchantStats,fetchMerchantNotificationsCount } from '../actions/merchant';

const initialState = {
  loading: false,
  merchant: null,
  merchant_details: null,
  merchant_stats: null,
  jwtToken: null, // for storing the JWT
  error: null,
  success: false,
  unreadNotifications:0
};

const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {},
  extraReducers: {

    // Merchant Login
    [merchantLogin.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [merchantLogin.fulfilled.toString()]: (state, {payload}) => {
      state.loading = false;
      state.merchant = payload?.data?.merchant;
    },
    [merchantLogin.rejected.toString()]: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
    // Merchant Change Password
    [merchantChangePassword.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [merchantChangePassword.fulfilled.toString()]: (state, {payload}) => {
      state.loading = false;
    },
    [merchantChangePassword.rejected.toString()]: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
    // Merchant Home
    [merchantHome.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [merchantHome.fulfilled.toString()]: (state, {payload}) => {
      state.loading = false;
      state.merchant_details = payload;
    },
    [merchantHome.rejected.toString()]: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
    // Merchant Stats
    [getMerchantStats.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [getMerchantStats.fulfilled.toString()]: (state, {payload}) => {
      state.loading = false;
      state.merchant_stats = payload;
    },
    [getMerchantStats.rejected.toString()]: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
     // Merchant Notifications
     [fetchMerchantNotificationsCount.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [fetchMerchantNotificationsCount.fulfilled.toString()]: (state, {payload}) => {
      state.loading = false;
      state.unreadNotifications =  payload
 
    
    },
    [fetchMerchantNotificationsCount.rejected.toString()]: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default merchantSlice.reducer;
