import { useState } from "react";
import { Button, Typography, useMediaQuery } from "@mui/material";
import { APP_FONT } from "../constants/AppFont";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { apiCall } from "../apis/api";
import AlertComponent from "./alert/AlertComponent";

const CsvDownloader = () => {
  const [fetchingData, setFetchingData] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const merchantId = localStorage.getItem("merchantId");
  const isMobileOrTablet = useMediaQuery("(max-width: 639px)");

  const fetchAndDownloadTransactions = async () => {
    try {
      setFetchingData(true);
      setError(false); // Reset error state before making the API call
      const response = await apiCall(
        "api/v1/merchant/download-transaction",
        "get"
      );
      const csvData = response?.data;
      if (csvData?.length > 0) {
        const csvString = convertToCsv(csvData);
        const csvBlob = new Blob([csvString], {
          type: "text/csv;charset=utf-8;",
        });
        const csvUrl = URL.createObjectURL(csvBlob);
        const link = document.createElement("a");
        link.href = csvUrl;
        link.setAttribute("download", `${merchantId}-${new Date()}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setError(true);
        setErrorMsg("No statements available for download.");
      }
    } catch (error) {
    } finally {
      setFetchingData(false);
    }
  };

  const convertToCsv = (data: any) => {
    const csvRows = [];
    // Header
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));
    // Values
    data.forEach((row: any) => {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    });
    return csvRows.join("\n");
  };

  return (
    <>
      {error && <AlertComponent severity="error" message={errorMsg} />}
      <Button
        variant="outlined"
        sx={{
          fontFamily: APP_FONT,
          textTransform: "none",
          color: "black",
          border: "1px solid black",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        }}
        startIcon={<SaveAltOutlinedIcon />}
        onClick={fetchAndDownloadTransactions}
        disabled={fetchingData}
      >
        <Typography variant="inherit">
          {fetchingData
            ? "Fetching..."
            : !isMobileOrTablet
            ? "Download Statement"
            : "Download"}
        </Typography>
      </Button>
    </>
  );
};

export default CsvDownloader;
