import React, { useEffect, useState } from "react";
import DataTable from "../table/Table";
import { Box, Tab } from "@mui/material";
import { APP_FONT } from "../../constants/AppFont";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { fetchPlaidAndReceipt } from "../../redux/actions/merchant";
import { useLocation } from "react-router-dom"; // Import useHistory hook from react-router-dom

type PlaidData = [];
type ReceiptData = [];

export default function Transactions() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialTabValue = searchParams.get("val") || "1";

  const [value, setValue] = useState<string>(initialTabValue);
  const [plaidData, setPlaidData] = useState<PlaidData>([]);
  const [receiptData, setReceiptData] = useState<ReceiptData>([]);
  const [loadingPlaid, setLoadingPlaid] = useState<boolean>(false);
  const [loadingReceipt, setLoadingReceipt] = useState<boolean>(false);
  const [pagination, setPagination] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const dispatch = useDispatch();

  const columns = [
    { label: "Name", key: "user" },
    { label: "$ Amount", key: "amount" },
    { label: "$ Merchant Profit", key: "merchant_profit" },
    { label: "$ Admin Profit", key: "admin_profit" },
    { label: "$ Karla Rewards", key: "karla_rewards" },
    { label: "Points", key: "points" },
    { label: "Date", key: "date" },
  ];

  const fetchData = async (page: any, rowsPerPage: any) => {
    try {
      if (value === "1") {
        setLoadingPlaid(true);
        dispatch<any>(
          fetchPlaidAndReceipt({ isPlaid: true, page, rowsPerPage })
        )
          .then(unwrapResult)
          .then((res: any) => {
            if (res) {
              setPlaidData(res?.data);
              setPagination(res?.pagination);
              setLoadingPlaid(false);
            }
          });
      } else if (value === "2") {
        setLoadingReceipt(true);
        dispatch<any>(
          fetchPlaidAndReceipt({ isPlaid: false, page, rowsPerPage })
        )
          .then(unwrapResult)
          .then((res: any) => {
            if (res) {
              setReceiptData(res?.data);
              setPagination(res?.pagination);
              setLoadingReceipt(false);
            }
          });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingPlaid(false);
      setLoadingReceipt(false);
    }
  };

  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, [value, page, rowsPerPage]);

  useEffect(() => {
    setPage(1);
    setRowsPerPage(5);
    fetchData(1, 5);
  }, [value]);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.delete("val");
    window.history.replaceState({}, document.title, newUrl.toString());
  };

  return (
    <Box sx={{ width: "96.5%", typography: "body1", fontFamily: APP_FONT }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" ,}}>
          <TabList
            sx={{
              fontFamily: APP_FONT,
              fontWeight: 600,
              textTransform: "none",
            }}
            indicatorColor="secondary"
            textColor="secondary"
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab
              sx={{ fontWeight: 600, fontFamily: APP_FONT }}
              label="Plaid"
              value="1"
            />
            <Tab
              sx={{ fontWeight: 600, fontFamily: APP_FONT }}
              label="Receipt"
              value="2"
            />
          </TabList>
        </Box>
        <TabPanel sx={{p:0,pt:2,pl:0}} value="1">
          <DataTable
            rows={plaidData}
            loading={loadingPlaid}
            pagination={pagination}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            columns={columns}
          />
        </TabPanel>

        <TabPanel sx={{p:0,pt:2,pl:0}} value="2">
          <DataTable
            rows={receiptData}
            loading={loadingReceipt}
            pagination={pagination}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            columns={columns}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
