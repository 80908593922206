import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";

interface ColumnDefinition {
  label: string;
  key: string;
}

interface RowData {
  [key: string]: any;
}

interface DataTableProps {
  rows: RowData[];
  loading: boolean;
  pagination: any;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  columns: ColumnDefinition[];
  error?: boolean;
  errorMessage?: string;
}

const DataTable: React.FC<DataTableProps> = ({
  rows,
  loading,
  pagination,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  columns,
}) => {
  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(1);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.key}
                  sx={{
                    maxWidth: 200,
                    wordWrap: "break-word",
                    whiteSpace: "nowrap",
                    fontWeight:600
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <SkeletonLoader columns={columns.length} />
            ) : rows?.length ? (
              rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.key}
                      sx={{ maxWidth: 200, wordWrap: "break-word",  whiteSpace: "nowrap", }}
                    >
                      {column.key === "invoice_url" ? (
                        <a
                          href={row[column.key]}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          View Invoice
                        </a>
                      ) : (
                        row[column.key] || "N / A"
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns?.length} align="center">
                  No Transaction Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={pagination && pagination.total}
        rowsPerPage={rowsPerPage}
        page={page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DataTable;
