import React, { useEffect, useState } from "react";
import { Paper, Stack, Typography, Button } from "@mui/material";
import { APP_FONT } from "../constants/AppFont";
import { useDispatch, useSelector } from "react-redux";
import { merchantHome } from "../redux/actions/merchant";
import AddCardIcon from "@mui/icons-material/AddCard";

const stripe = require("stripe")(process.env.REACT_APP_STRIPE_SECRET_KEY);

interface BusinessEwalletProps {
  setError?: (value: boolean) => void;
  setErrorMessage?: (message: string) => void;
}

const BusinessEwallet: React.FC<BusinessEwalletProps> = ({
  setError = () => {}, // Default to empty function if not provided
  setErrorMessage = () => {}, // Default to empty function if not provided
}) => {
  const dispatch = useDispatch();
  const [topUpLoader, setTopUpLoader] = useState(false);
  const balance = useSelector(
    (state: any) => state.merchant.merchant_details?.data?.balance
  );
  const email = localStorage.getItem("merchantEmail");
  const category = localStorage.getItem("category");
  const location = localStorage.getItem("location");

  const fetchMerchantData = async () => {
    try {
      await dispatch<any>(merchantHome());
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchMerchantData();
  }, [dispatch]);

  const onPaymentClick = async () => {
    setTopUpLoader(true);
    const paymentLink = await stripe.paymentLinks.create({
      payment_method_types: ['card'],
      line_items: [
        {
          price: process.env.REACT_APP_PRICE_ID,
          quantity: 1,
        },
      ],
    });
    

    if (paymentLink) {
      setTopUpLoader(false);
      setError(false); // Set error state directly
      setErrorMessage(""); // Set error message directly
      window.location.href =
        process.env.REACT_APP_PAYMEMT_LINK + "?prefilled_email=" + email;
    } else {
      setTopUpLoader(false);
      setError(true); // Set error state directly
      setErrorMessage("Could not fetch payment details. Please contact admin."); // Set error message directly
    }
  };

  return (
    <Paper elevation={0} variant="outlined" sx={{ p: 2 }}>
      <Typography
        fontWeight={700}
        marginBottom={"27px"}
        sx={{ color: "#0E162C", fontFamily: APP_FONT, fontSize: "1rem" }}
      >
        Business E-Wallet
      </Typography>

      <Paper elevation={0} sx={{ padding: 2, backgroundColor: "#EAE5F1" }}>
        <Stack spacing={2}>
          <Typography fontFamily={APP_FONT} fontWeight={600} fontSize={"1rem"}>
            {category} - {location}
          </Typography>
          <Typography fontFamily={APP_FONT} fontWeight={600} fontSize={"1rem"}>
            Rewards
          </Typography>
          <Typography alignSelf={"center"} fontSize={"2rem"} fontWeight={700}>
            ${balance|| "0.00"} 
          </Typography>
          <Typography
            fontFamily={APP_FONT}
            sx={{ color: "#0E162C", fontSize: "1rem" }}
          >
            **** **** **** 1234
          </Typography>
        </Stack>
      </Paper>

      <Button
        disabled={topUpLoader}
        sx={{
          fontFamily: APP_FONT,
          fontWeight: 600,
          color: "#000",
          borderColor: "#ececec",
          textTransform: "none",
          marginTop: "20px",
          marginBottom: "4px",
        }}
        fullWidth
        variant="outlined"
        onClick={onPaymentClick}
        startIcon={<AddCardIcon sx={{ marginRight: "8px" }} />}
      >
        Top Up
      </Button>
    </Paper>
  );
};

export default BusinessEwallet;
