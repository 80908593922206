import { useState, useEffect } from "react";
import {
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Stack,
  Divider,
  IconButton,
  Grid,
  LinearProgress,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  merchantHome,
  merchantLogout,
  storePaymentSuccessData,
} from "../redux/actions/merchant";
import { unwrapResult } from "@reduxjs/toolkit";
import { ReactComponent as CustomerSupport } from "../assets/images/logos/customer-support.svg";
import { ReactComponent as CallIcon } from "../assets/images/logos/call.svg";
import { ReactComponent as MailIcon } from "../assets/images/logos/mail-01.svg";
import { APP_FONT } from "../constants/AppFont";
import FullWidthTabs from "./tabs/Info";
import Qrcode from "./qr-code";
import AlertComponent from "./alert/AlertComponent";
import { useNavigate } from "react-router-dom";
import BusinessEwallet from "./BusinessEwallet";
import EarnedRevenue from "./EarnedRevenue";

const MerchantDashboard = ({
  setError,
  setErrorMessage,
  alertSeverity,
  setAlertSeverity,
  alertMessage,
  setAlertMessage,
}: any) => {
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const qrcode = useSelector(
    (state: any) => state.merchant?.merchant_details?.data.qrcode
  );

  const receipt_transactions = useSelector(
    (state: any) => state.merchant?.merchant_details?.data?.receipt_transactions
  );

  const plaid_transactions = useSelector(
    (state: any) => state.merchant?.merchant_details?.data?.plaid_transactions
  );

  const merchantId = localStorage.getItem("merchantId") || "";

  useEffect(() => {
    if (!merchantId || merchantId == "") {
      setAlertSeverity("error");
      setAlertMessage("Merchant ID not found. Please Login again!");
    }
  }, [merchantId]);

  const handleNavigate = (val: any) => {
    navigate(`/transactions?val=${val}`);
  };

  useEffect(() => {
    const saveMerchantPayment = async () => {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        const sessionId = searchParams.get("sessionId");

        if (sessionId) {
          const paymentRes = await dispatch<any>(
            storePaymentSuccessData(sessionId)
          );
          const paymentData = unwrapResult(paymentRes);

          if (paymentData?.data?.success) {
            setAlertSeverity("success");
            setAlertMessage("Paid Successfully");
            removeSessionIdFromUrl();
          } else {
            setError(true);
            setErrorMessage("Error. Could not save merchant payment.");
          }
        }
      } catch (err) {
        setError(true);
        setErrorMessage("Error. Could not fetch merchant data.");
      } finally {
        setLoading(false);
      }
    };

    saveMerchantPayment();
  }, []);

  const fetchMerchantData = async () => {
    try {
      await dispatch<any>(merchantHome());
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchMerchantData();
  }, [dispatch]);

  const removeSessionIdFromUrl = () => {
    const newUrl = window.location.origin + window.location.pathname;
    window.history.replaceState({}, document.title, newUrl);
  };

  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      {alertSeverity && alertMessage && (
        <AlertComponent severity={alertSeverity} message={alertMessage} />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <BusinessEwallet
            setError={setError}
            setErrorMessage={setErrorMessage}
          />

          <Paper elevation={0} variant="outlined" sx={{ p: 2, mt: 3 }}>
            <EarnedRevenue />

            <Paper
              elevation={0}
              variant="outlined"
              sx={{
                backgroundColor: "#F6F4F4",
                marginTop: 4,
                paddingTop: "17px",
                paddingLeft: "20px",
                paddingBottom: "20px",
                width: "auto",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  sx={{
                    backgroundColor: "#fff",
                    "&:hover": {
                      backgroundColor: "#fff",
                    },
                    marginRight: "10px", // Adjusted margin for the icon
                  }}
                >
                  <CustomerSupport width={25} height={25} />
                </IconButton>
                <Typography
                  fontFamily={APP_FONT}
                  fontWeight={600}
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    fontSize: "1rem",
                  }}
                >
                  Contact Merchant Support
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  sx={{
                    backgroundColor: "#fff",
                    "&:hover": {
                      backgroundColor: "#fff",
                    },
                    marginRight: "10px", // Adjusted margin for the icon
                  }}
                >
                  <MailIcon />
                </IconButton>
                <Typography
                  fontFamily={APP_FONT}
                  fontWeight={600}
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    fontSize: "1rem",
                    wordBreak: "break-word",
                    paddingRight: "10px",
                  }}
                >
                  merchants@co-karla.com
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  sx={{
                    backgroundColor: "#fff",
                    "&:hover": {
                      backgroundColor: "#fff",
                    },
                    marginRight: "10px", // Adjusted margin for the icon
                  }}
                >
                  <CallIcon />
                </IconButton>
                <Typography
                  fontFamily={APP_FONT}
                  fontWeight={600}
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                    fontSize: "1rem",
                  }}
                >
                  514-815-5668
                </Typography>
              </Box>
            </Paper>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {plaid_transactions?.data?.length > 0 ? (
              <TableContainer
                elevation={0}
                component={Paper}
                sx={{ maxHeight: 348 }}
                variant="outlined"
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  padding={"16px"}
                >
                  <Typography
                    fontWeight={700}
                    variant="h6"
                    sx={{
                      color: "#0E162C",
                      fontFamily: APP_FONT,
                      fontSize: "1rem",
                    }}
                  >
                    Plaid Transactions
                  </Typography>
                  <Button
                    onClick={() => handleNavigate(1)}
                    style={{
                      fontSize: "0.9rem",
                      color: "#7D818B",
                      textTransform: "none",
                      fontFamily: APP_FONT,
                    }}
                  >
                    View All
                  </Button>
                </Stack>
                <Divider />
                <Table size="small" aria-label="a dense table">
                  <TableHead sx={{ background: "#F6F4F4" }}>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#8E95A6",
                          fontFamily: APP_FONT,
                          fontSize: "0.85rem",
                        }}
                        align="left"
                      >
                        CUSTOMER
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#8E95A6",
                          fontFamily: APP_FONT,
                          fontSize: "0.85rem",
                        }}
                        align="left"
                      >
                        AMOUNT
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#8E95A6",
                          fontFamily: APP_FONT,
                          fontSize: "0.85rem",
                        }}
                        align="right"
                      >
                        DATE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {plaid_transactions?.data?.map((transaction: any) => (
                      <TableRow key={transaction.id}>
                        <TableCell align="left" sx={{ fontSize: "0.85rem" }}>
                          {transaction.user || "-"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: "0.85rem" }}>
                          {`$ ${transaction.amount || "-"}`}
                        </TableCell>
                        <TableCell align="right" sx={{ fontSize: "0.85rem" }}>
                          {transaction.date || "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Paper
                elevation={0}
                variant="outlined"
                sx={{
                  p: 2,
                  height: 348,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  fontWeight={700}
                  fontFamily={APP_FONT}
                  fontSize={"16px"}
                  height={"50%"}
                  width={"100%"}
                >
                  Plaid Transactions
                </Typography>
                <Typography
                  fontWeight={700}
                  fontFamily={APP_FONT}
                  fontSize={"16px"}
                >
                  No Plaid Transactions Found
                </Typography>
              </Paper>
            )}
          </Paper>

          <Paper sx={{ marginTop: 3, width: "100%", overflow: "hidden" }}>
            {receipt_transactions?.data?.length > 0 ? (
              <TableContainer
                elevation={0}
                component={Paper}
                sx={{ maxHeight: 405 }}
                variant="outlined"
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  padding={"16px"}
                >
                  <Typography
                    fontFamily={APP_FONT}
                    fontWeight={700}
                    fontSize={"16px"}
                  >
                    Receipt Transactions
                  </Typography>
                  <Button
                    onClick={() => handleNavigate(2)}
                    style={{
                      color: "#7D818B",
                      textTransform: "none",
                      fontFamily: APP_FONT,
                      fontSize: "14px",
                    }}
                  >
                    View All
                  </Button>
                </Stack>
                <Divider />
                <Table>
                  <TableHead sx={{ background: "#F6F4F4" }}>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#8E95A6",
                          fontFamily: APP_FONT,
                          fontSize: "0.85rem",
                        }}
                        align="left"
                      >
                        CUSTOMER
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#8E95A6",
                          fontFamily: APP_FONT,
                          fontSize: "0.85rem",
                        }}
                        align="left"
                      >
                        AMOUNT
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#8E95A6",
                          fontFamily: APP_FONT,
                          fontSize: "0.85rem",
                        }}
                        align="right"
                      >
                        DATE
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {receipt_transactions?.data?.map((transaction: any) => (
                      <TableRow key={transaction.id}>
                        <TableCell align="left" sx={{ fontSize: "0.85rem" }}>
                          {transaction.user || "-"}
                        </TableCell>
                        <TableCell align="left" sx={{ fontSize: "0.85rem" }}>
                          {`$ ${transaction.amount || "-"}`}
                        </TableCell>
                        <TableCell align="right" sx={{ fontSize: "0.85rem" }}>
                          {transaction.date || "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Paper
                elevation={0}
                variant="outlined"
                sx={{
                  p: 2,
                  height: 348,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  fontWeight={700}
                  fontFamily={APP_FONT}
                  fontSize={"16px"}
                  height={"50%"}
                  width={"100%"}
                >
                  Receipt Transactions
                </Typography>
                <Typography
                  fontWeight={700}
                  fontFamily={APP_FONT}
                  fontSize={"16px"}
                >
                  No Receipt Transactions Found
                </Typography>
              </Paper>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper elevation={0} variant="outlined">
            {/* Will be added when backend functionality will be there */}
            <FullWidthTabs />
            <Qrcode
              setError={setError}
              setErrorMessage={setErrorMessage}
              qrCode={qrcode}
              merchantId={merchantId}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default MerchantDashboard;
