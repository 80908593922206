import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Avatar, Badge, Button, Stack, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { APP_FONT } from "../../constants/AppFont";
import { ReactComponent as KarlaLogo } from "../../assets/images/logos/Karla_logo_color 1.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/logos/dashboard-square-03.svg";
import { ReactComponent as TransactionIcon } from "../../assets/images/logos/circle-arrow-data-transfer-diagonal.svg";
import { ReactComponent as EWalletIcon } from "../../assets/images/logos/wallet-01.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/logos/setting-01.svg";
import { ReactComponent as CommentIcon } from "../../assets/images/logos/comment-01.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logos/logout-04.svg";
import {
  fetchMerchantNotificationsCount,
  merchantLogout,
} from "../../redux/actions/merchant";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import MyModal from "../modal/Modal";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

const drawerWidth = 240;

const theme = createTheme({
  typography: {
    fontFamily: APP_FONT,
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#ececec",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 600,
        },
      },
    },
  },
});

const drawerTab = {
  mt: "10px",
  marginY: "5px",
  color: "#0E162C",
  "&:hover": {
    color: "#0E162C",
  },
};

interface Props {
  windowDrawer?: () => Window;
}

export default function HomeDrawer(props: Props) {
  const { windowDrawer } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const merchantName = localStorage.getItem("merchantName");
  const category = localStorage.getItem("category");
  const location = localStorage.getItem("location");
  const currentPath = window.location.pathname;
  const [focusedItem, setFocusedItem] = useState(-1);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMobileOrTablet = useMediaQuery("(max-width: 639px)");
  const islarge = useMediaQuery("(max-width: 1170px)");

  const [logoutLoader, setLogoutLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);


  const unreadNotifications = useSelector(
    (state: any) => state.merchant.unreadNotifications
  );
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const container =
    windowDrawer !== undefined ? () => windowDrawer().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const merchantNotifications = async () => {
    try {
      await dispatch<any>(fetchMerchantNotificationsCount());
    } catch (error) {}
  };

  useEffect(() => {
    merchantNotifications();
  }, []);

  useEffect(() => {
    if (currentPath === "/") {
      setFocusedItem(0);
    } else if (currentPath === "/transactions") {
      setFocusedItem(1);
    } else if (currentPath === "/e-wallet") {
      setFocusedItem(2);
    } else if (currentPath === "/notifications") {
      setFocusedItem(3);
    } else if (currentPath === "/consult-loyalty-advisor") {
      setFocusedItem(4);
    } else {
      setFocusedItem(-1);
    }
  }, [currentPath]);

  const handleLogout = () => {
    setLogoutLoader(true);

    // TODO: Handle this case gracefully. Right now we're just clearly LS and navigate user to login page irrespective of the response.
    dispatch<any>(merchantLogout())
      .then(unwrapResult)
      .then((res: any) => {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("merchantName");
        localStorage.removeItem("category");
        localStorage.removeItem("location");
        localStorage.removeItem("merchantEmail");
        localStorage.removeItem("updatedPassword");

        setLogoutLoader(false);
        navigate(`/login`);
      })
      .catch((err: any) => {
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("merchantName");
        localStorage.removeItem("category");
        localStorage.removeItem("location");
        localStorage.removeItem("merchantEmail");
        localStorage.removeItem("updatedPassword");
        navigate(`/login`);
        console.log(err);
      });
  };

  const drawer = (
    <div>
      <Toolbar>
        <KarlaLogo style={{ marginRight: 10 }} />
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            fontFamily: APP_FONT,
            color: "#0E162C",
            mt: 2.5,
          }}
        >
          Merchants
        </Typography>
        {isMobileOrTablet && (
          <Box sx={{ float: "right", position: "absolute", right: 0, top: 0 }}>
            <IconButton>
              <CloseIcon
                onClick={handleDrawerToggle}
                sx={{ fontSize: "1.2rem" }}
              />
            </IconButton>
          </Box>
        )}
      </Toolbar>
      <Divider sx={{ mb: 2 }} />
      {isMobileOrTablet ? (
        <>
          <Typography
            noWrap
            component="div"
            sx={{
              fontFamily: APP_FONT,
              fontSize: 16,
              marginLeft: 2,
              fontWeight: 700,
              color: "#0E162C",
            }}
          >
            {merchantName || ''}
          </Typography>
          <Typography
            noWrap
            component="div"
            sx={{
              fontFamily: APP_FONT,
              fontSize: 14,
              marginLeft: 2,
              color: "#0E162C",
              alignItems: "center",
              textWrap: "wrap",
            }}
          >
           {location && location !== "null" ? `(${location})` : ""}
          </Typography>
          <Divider sx={{ mb: 2, mt: 2 }} />
        </>
      ) : (
        <></>
      )}
      <List>
        <ThemeProvider theme={theme}>
          <ListItem
            key={"Dashboard"}
            onClick={() => navigate("/")}
            disablePadding
            sx={{ marginBottom: 0.5 }}
          >
            <ListItemButton
              selected={focusedItem === 0}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#ececec",
                  "&:hover": {
                    backgroundColor: "#ececec",
                  },
                },
              }}
            >
              <DashboardIcon
                height={24}
                width={24}
                style={{ marginRight: "15px" }}
              />
              <ListItemText sx={drawerTab} primary={"Dashboard"} />
            </ListItemButton>
          </ListItem>

          <ListItem
            key={"Transactions"}
            onClick={() => navigate("/transactions")}
            disablePadding
            sx={{ marginBottom: 0.5 }}
          >
            <ListItemButton
              selected={focusedItem === 1}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#ececec",
                  "&:hover": {
                    backgroundColor: "#ececec",
                  },
                },
              }}
            >
              <TransactionIcon
                height={24}
                width={24}
                style={{ marginRight: "15px" }}
              />
              <ListItemText sx={drawerTab} primary={"Transactions"} />
            </ListItemButton>
          </ListItem>

          <ListItem
            key={"E-Wallet"}
            onClick={() => navigate("/e-wallet")}
            disablePadding
            sx={{ marginBottom: 0.5 }}
          >
            <ListItemButton
              selected={focusedItem === 2}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#ececec",
                  "&:hover": {
                    backgroundColor: "#ececec",
                  },
                },
              }}
            >
              <EWalletIcon
                height={24}
                width={24}
                style={{ marginRight: "15px" }}
              />
              <ListItemText sx={drawerTab} primary={"E-Wallet"} />
            </ListItemButton>
          </ListItem>

          <ListItem
            key={"Notifications"}
            onClick={() => navigate("/notifications")}
            disablePadding
            sx={{ marginBottom: 0.5 }}
          >
            <ListItemButton
              selected={focusedItem === 3}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#ececec",
                  "&:hover": {
                    backgroundColor: "#ececec",
                  },
                },
              }}
            >
              <Badge badgeContent={unreadNotifications} color="secondary">
                <NotificationsNoneOutlinedIcon sx={{ color: "#555b69" }} />
              </Badge>
              <Box sx={{ ml: 1.5 }}>
                {" "}
                <ListItemText sx={drawerTab} primary={"Notifications"} />
              </Box>
            </ListItemButton>
          </ListItem>

          {/* Consult Loyalty Advisor Tab For Future Use*/}
          {/* <ListItem
            key={"Consult Loyalty Advisor"}
            onClick={() => navigate("/consult-loyalty-advisor")}
            disablePadding
            sx={{ marginBottom: 0.5 }}
          >
            <ListItemButton
              selected={focusedItem === 4}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#ececec",
                  "&:hover": {
                    backgroundColor: "#ececec",
                  },
                },
              }}
            >
              <CommentIcon
                height={24}
                width={24}
                style={{ marginRight: "15px" }}
              />
              <ListItemText
                sx={drawerTab}
                primary={"Consult Loyalty Advisor"}
              />
            </ListItemButton>
          </ListItem> */}
        </ThemeProvider>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: !isMobileOrTablet ? `calc(100% - ${drawerWidth}px)` : "100%",
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#fff",
        }}
      >
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              mr: 2,
              color: "#000",
              display: !isMobileOrTablet ? "none" : "block",
            }}
          >
            <MenuIcon />
          </IconButton>
          {isMobileOrTablet ? (
            <></>
          ) : (
            <>
              <Typography
                noWrap
                component="div"
                sx={{
                  fontFamily: APP_FONT,
                  fontSize: 16,
                  fontWeight: 700,
                  color: "#0E162C",
                }}
              >
                {merchantName || ""}
              </Typography>
              <Typography
                noWrap
                component="div"
                sx={{
                  fontFamily: APP_FONT,
                  fontSize: 14,
                  marginLeft: 1,
                  color: "#0E162C",
                  alignItems: "center",
                }}
              >
                {location && location !== "null" ? `(${location})` : ""}
              </Typography>
            </>
          )}
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              marginLeft: "auto",
            }}
          >
            {isMobileOrTablet ? (
              <></>
            ) : (
              <Button
                onClick={handleOpenModal}
                variant="contained"
                sx={{
                  marginRight: 5,
                  borderRadius: "7px",
                  fontFamily: APP_FONT,
                  textTransform: "none",
                  color: "#fff",
                  background: "#582F8E",
                  "&:hover": {
                    color: "#fff",
                    background: "#582F8E",
                  },
                }}
              >
                <Typography sx={{ fontSize: "0.8rem" }}>
                  {islarge ? "Join " : "Join Profit Sharing Program"}
                </Typography>
              </Button>
            )}

            <IconButton sx={{ ml: "auto" }}>
              <Avatar
                sx={{
                  fontFamily: APP_FONT,
                  height: isMobileOrTablet ? 24 : 32,
                  width: isMobileOrTablet ? 24 : 32,
                  backgroundColor: "#EAECF1",
                  color: "#0E162C",
                }}
              >
                <Typography
                  sx={{
                    fontSize: isMobileOrTablet ? "14px" : "16px",
                  }}
                >
                  {merchantName && merchantName.charAt(0).toUpperCase()}
                </Typography>
              </Avatar>
            </IconButton>

            <Typography
              color="inherit"
              sx={{
                fontFamily: APP_FONT,
                fontWeight: 500,
                fontSize: isMobileOrTablet ? "14px" : "16px",
                color: "#0E162C",
              }}
            >
              {merchantName}
            </Typography>
            <LoadingButton
              loading={logoutLoader}
              onClick={handleLogout}
              sx={{
                marginLeft: isMobileOrTablet ? 2 : 5,
                fontFamily: APP_FONT,
                textTransform: "none",
                color: "#0E162C",
                marginRight: isMobileOrTablet ? 0.7 : 0,
              }}
            >
              <LogoutIcon
                width={isMobileOrTablet ? 20 : 24}
                height={isMobileOrTablet ? 20 : 24}
              />
              <Typography
                sx={{
                  fontSize: isMobileOrTablet ? "0.9rem" : "1.1rem",
                  ml: 0.5,
                }}
              >
                Logout
              </Typography>
            </LoadingButton>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      {!isMobileOrTablet && (
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      )}
      <MyModal open={modalOpen} handleClose={handleCloseModal} />
    </Box>
  );
}
