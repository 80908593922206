import React, { useEffect, useState } from "react";
import {
  TextField,
  Paper,
  Typography,
  Alert,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { merchantChangePassword } from "../../redux/actions/merchant";
import { MerchantChangePassword } from "../../models/merchant";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const ChangePassword = () => {
  const jwtToken = localStorage.getItem("jwtToken");
  const isAuthenticated = !!jwtToken; // Check if a JWT token exists
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login')
    }
  })
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };

  const handleChangePassword = async () => {
    setLoading(true);
    const merchantEmail = localStorage.getItem('merchantEmail');

    const body: MerchantChangePassword = {
      email: merchantEmail || '',
      password: password,
      confirmPassword: confirmPassword
    }

    if (password !== confirmPassword) {
      setError(true);
      setErrorMessage('Passwords do not match.');
      setLoading(false);
    } else {
      dispatch<any>(merchantChangePassword(body))
        .then(unwrapResult)
        .then((res: any) => {
          setLoading(false);
          if (res) {
            // navigate to home page
            navigate('/');
          } else {
            setError(true);
            setErrorMessage('Error. Could not change password');
          }
        })
        .catch((err: any) => {
          setLoading(false);
          setError(true);
          setErrorMessage('Error. Could not change password');
        });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <div>
        <Typography variant="h4" gutterBottom align="center">
          Add New Password
        </Typography>
        <Paper
          elevation={3}
          style={{ padding: "20px", maxWidth: "400px", width: "100%" }}
        >
          {
            !!error && <Alert severity="error">{errorMessage}</Alert>
          }
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            margin="normal"
            required={true}
          />
          <TextField
            label="Confirm Password"
            variant="outlined"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            margin="normal"
            required={true}
          />
          <LoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            onClick={handleChangePassword}
            fullWidth
            disabled={password === '' || confirmPassword === ''}
          >
            Change Password
          </LoadingButton>
        </Paper>
      </div>
    </div>
  );
};

export default ChangePassword;
